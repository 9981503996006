// JavaScript Document
if(!window.console){
    window.console = {
        log:function(){}
    };
}

var FloatingFlag=0;
var StickyFlag=0;
var ReviewFlag=0;
var _TrackId = '';

(function($) {
       $.triggertabplugin = {
              defaultsTriggerTabParam: {
                     btnContainer: "_triggertabcontainer",
                     HotelId:'26',
                     GroupId:'',//Chinmay Gandhi - 21th Aug 2019 - L2B in MHBE [ RES-2099|2 ]
                     langkey:'en',
                     location:"onLeft",
                     btncolor:'#2196F3',
                     langtext:'English',
                     serverurl:"https://live.ipms247.com/",
                     IsLayout:0,//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
              }
       };  
	
	$.fn.extend({
        triggertabplugin:function(bkTriggerConfig) {
            var options = $.extend({}, $.triggertabplugin.defaultsTriggerTabParam, bkTriggerConfig);  
            options.btnContainer = this.attr("id");
            methods._GetDataContain(options);
        }
    });
       
    var methods = {
        test:'123',
            _GetDataContain:function(opt){
                try{
                    //Chinmay Gandhi - 21th Aug 2019 - L2B in MHBE [ RES-2099|2 ] - Start
                    if(opt.GroupId != '')
                    {
                        var getUrl=opt.serverurl+'booking/multiproperty_service.php';
                        var param='action=GetTriggerContain&lang_code='+opt.langkey+'&GroupCode='+opt.GroupId;
                    }//Chinmay Gandhi - 21th Aug 2019 - L2B in MHBE [ RES-2099|2 ] - End
                    else
                    {
                        var getUrl=opt.serverurl+'booking/service.php';
                        var param='action=GetTriggerContain&lang_code='+opt.langkey+'&HotelId='+opt.HotelId;
                    }

                    jQuery.ajax({
                           type: "POST",
                           url: getUrl,
                           data: param,
                           success: function(response) {
                                var response = JSON.parse(jQuery.trim(response));
                                var res = response.content;
                                _TrackId = response.TrackId;
                                opt.IsLayout = response.IsLayout;//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                                var MainContain =   "<section class='eRside-trigger'>"
                                                        +res+
                                                    "</section>";

                                jQuery("#_triggertabcontainer").html(MainContain);
                                if(opt.location == 'onRight')
                                {
                                    jQuery(".eRside-trigger").addClass('onRight');
                                }
                                
                           },
                           complete: function(e){
                                 methods._OpenTooltrigger(opt,_TrackId);
                           }
                    });
                }
                catch(err){
                      console.log('error message _GetDataContain');
                      console.log(err);
                }
            },
            _OpenTooltrigger:function(opt,trackid){
                jQuery('#Trigger_Info').on('click', function(e){
                    methods._FloatingToolContain(opt,trackid);
                });
                jQuery('#Sticky_trigger').on('click', function(e){
                    methods._StickyToolContain(opt,trackid);
                });
                if(opt.IsLayout != 2){//Chinmay Gandhi - 21th Aug 2019 - L2B in MHBE [ RES-2099|2 ]
                    jQuery('#Review_trigger').on('click', function(e){
                        methods._ReviewToolContain(opt,trackid);
                    });
                }
            },
            _FloatingToolContain:function(opt,trackid){
                try{
                    //Chinmay Gandhi - 21th Aug 2019 - L2B in MHBE [ RES-2099|2 ] - Start
                    if(opt.GroupId != '')
                    {
                        var getUrl=opt.serverurl+'booking/multiproperty_service.php';
                        var param='action=GetFloatingContain&lang_code='+opt.langkey+'&GroupCode='+opt.GroupId+'&isTrigger=1';
                    }//Chinmay Gandhi - 21th Aug 2019 - L2B in MHBE [ RES-2099|2 ] - End
                    else
                    {
                        var getUrl=opt.serverurl+'booking/service.php';
                        var param='action=GetFloatingContain&lang_code='+opt.langkey+'&HotelId='+opt.HotelId+'&isTrigger=1';
                    }
                    
                    jQuery.ajax({
                           type: "POST",
                           url: getUrl,
                           data: param,
                           success: function(response) {
                                var response = JSON.parse(jQuery.trim(response));
                                opt.IsLayout = response.IsLayout;//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                                var res = response.content.split('<:>');//Chinmay Gandhi - 1.0.53.61 - 29th jan 2018 - LookertoBookerGoogleDrivingWidget
                                var content = jQuery.trim(res[0]);//Chinmay Gandhi - 1.0.53.61 - 29th jan 2018 - LookertoBookerGoogleDrivingWidget
                                var MainContain =   "<section class='WebShowHighlighted open'>"+
                                                        "<div class='model-main-content'>"+content+
                                                        "</div>"+
                                                        "<div class='slide-in-out toggle-sidebar'></div>"+
                                                    "</section>";

                                if(FloatingFlag == 0)
                                {
                                    jQuery('#_tooltabcontainer').removeClass('boxreview_wrap');
                                    jQuery('.triggeroverlay').removeClass('trigerblock');
                                    jQuery("#_tooltabcontainer").html('');
                                    jQuery("#_tooltabcontainer").html(MainContain);
                                    FloatingFlag=FloatingFlag+1;
                                    StickyFlag=0;
                                    ReviewFlag=0;
                                }
                                else
                                {
                                    jQuery("#_tooltabcontainer").html('');
                                    FloatingFlag=0;
                                }
                                
                                if(opt.location == 'onLeft')
                                {
                                    jQuery(".WebShowHighlighted").addClass('onRight');
                                }
                           },
                           complete: function(){
                                methods._RedirectToBooking(opt,trackid);
                                methods._ClosePopup(opt);
                           }
                    });
                }
                catch(err){
                      console.log('error message _FloatingToolContain');
                      console.log(err);
                }
            },
            _StickyToolContain:function(opt,trackid){
                try{
                    //Chinmay Gandhi - 21th Aug 2019 - L2B in MHBE [ RES-2099|2 ] - Start
                    if(opt.GroupId != '')
                    {
                        var getUrl=opt.serverurl+'booking/multiproperty_service.php';
                        var param='action=GetStickyContain&lang_code='+opt.langkey+'&GroupCode='+opt.GroupId+'&isTrigger=1';
                    }//Chinmay Gandhi - 21th Aug 2019 - L2B in MHBE [ RES-2099|2 ] - End
                    else
                    {
                        var getUrl=opt.serverurl+'booking/service.php';
                        var param='action=GetStickyContain&lang_code='+opt.langkey+'&HotelId='+opt.HotelId+'&isTrigger=1';
                    }

                    jQuery.ajax({
                           type: "POST",
                           url: getUrl,
                           data: param,
                           success: function(response) {
                                var response = JSON.parse(jQuery.trim(response));
                                opt.IsLayout = response.IsLayout;//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                                var res = response.content;
                                var MainContain = "<section id='eRbottom-fix-widget' class='eRbottom-fix-widget full'>"+res+
                                                  "</section>";

                                if(StickyFlag == 0)
                                {
                                    jQuery('#_tooltabcontainer').removeClass('boxreview_wrap');
                                    jQuery('.triggeroverlay').removeClass('trigerblock');
                                    jQuery("#_tooltabcontainer").html('');
                                    jQuery("#_tooltabcontainer").html(MainContain);
                                    StickyFlag=StickyFlag+1;
                                    FloatingFlag=0;
                                    ReviewFlag=0;
                                }
                                else
                                {
                                    jQuery("#_tooltabcontainer").html('');
                                    StickyFlag=0;
                                }
                           },
                           complete: function(){
                                methods._RedirectToBookingWithSticky(opt,trackid);
                                methods._ClosePopup(opt);
                           }
                    });
                }
                catch(err){
                    console.log('error message _StickyToolContain');
                    console.log(err);
                }
            },
            _ReviewToolContain:function(opt,trackid){
                try{
                    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
                    var eventer = window[eventMethod];
                    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
                    eventer(messageEvent,function(e){
                            console.log("parent received message!: ",e.data);
                            jQuery(".re_contentIframe").height(e.data);
                        },false);
                    
                    var MainContain = "<IFRAME SRC='"+opt.serverurl+"booking/reviews.php?HotelId="+opt.HotelId+"&perpage=5&frame=1&lang_code="+opt.langkey+"&langtext="+opt.langtext+"&TrackId="+_TrackId+"&isTrigger=1' width='100%' HEIGHT=100 frameborder='0' name='re_contentIframe' class='re_contentIframe' id='re_contentIframe'> </IFRAME>";
                    
                    if(ReviewFlag == 0)
                    {
                        jQuery('#_tooltabcontainer').toggleClass('boxreview_wrap');
                        jQuery('.triggeroverlay').toggleClass('trigerblock');
                        jQuery('html,body').animate({scrollTop: jQuery('.triggeroverlay').offset().top},'slow');
                        jQuery("#_tooltabcontainer").html('');
                        jQuery("#_tooltabcontainer").html(MainContain);
                        ReviewFlag=ReviewFlag+1;
                        FloatingFlag=0;
                        StickyFlag=0;
                    }
                    else
                    {
                        jQuery('#_tooltabcontainer').toggleClass('boxreview_wrap');
                        jQuery('.triggeroverlay').toggleClass('trigerblock');
                        jQuery("#_tooltabcontainer").html('');
                        ReviewFlag=0;
                    }
                    
                    jQuery( document ).on( "click", ".rewclosebtn", function() {
                        jQuery( "#Review_trigger" ).trigger( "click" );
                    });
                    
                    jQuery(window).on('scroll', function() {
                        var troverlayht = jQuery('body').innerHeight();
                        jQuery('.triggeroverlay').css('height',troverlayht);
                    });
                }
                catch(err)
                {
                    console.log('error message _ReviewToolContain');
                    console.log(err);
                }
            },
            _RedirectToBooking:function(opt,trackid){
                try{
                    jQuery('.flotbookbtn').on('click', function(e){
                        jQuery("a.flotbookbtn").removeAttr("href");
                        
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                        if(opt.IsLayout == 1)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+opt.HotelId+'&BookingThrough=3&L2BTrackId='+trackid+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else if(opt.IsLayout == 2)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+opt.GroupId+'&BookingThrough=3&L2BTrackId='+trackid+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+ opt.HotelId +'&BookingThrough=3&L2BTrackId=' + trackid,'_blank');
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                    });
                }
                catch(err){
                    console.log('error message _RedirectToBooking');
                    console.log(err);
                }
            },
            _RedirectToBookingWithSticky:function(opt,trackid){
                try{
                    jQuery('.stickybookbtn').on('click', function(e){
                        jQuery("a.stickybookbtn").removeAttr("href");
                        
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - Start
                        if(opt.IsLayout == 1)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+opt.HotelId+'&BookingThrough=5&L2BTrackId='+trackid+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else if(opt.IsLayout == 2)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+opt.GroupId+'&BookingThrough=5&L2BTrackId='+trackid+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+ opt.HotelId +'&BookingThrough=5&L2BTrackId=' + trackid,'_blank');
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                    });
                }
                catch(err){
                    console.log('error message _RedirectToBookingWithSticky');
                    console.log(err);
                }
            },
            _ClosePopup:function(opt){
                jQuery('.toggle-sidebar').on('click', function(e){
                    jQuery('.WebShowHighlighted').toggleClass('open');
                });
                jQuery('#toggle-bottombar').on('click', function(e) {
                    jQuery('#eRbottom-fix-widget').toggleClass('be_hide');
                });
            }
    }
})(jQuery);